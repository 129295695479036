.arrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.success-buying-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.bx-iso-block {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
}

.bx-iso-image {
  height: 30vh;
  width: auto;
}

@media screen and (max-width: 768px) {
  .bx-iso-image {
    height: 17vh;
  }
}

.confirm-button:hover {
  color: white;
  background: #1d5bf5!important;
}
